import React, { useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { ComoLoHacemos as Styled } from "./styled"

import Logo from "@/assets/LogoWhite.svg"
import Llave from "@/assets/Llave.svg"
import Casa from "@/assets/Casa.svg"
import Trabajador from "@/assets/Trabajador.svg"

const ComoLoHacemos = () => {
  const query = useStaticQuery(graphql`
    query {
      allDataJson(filter: {page: {eq: "proyectos"}, section: {eq: "como_lo_hacemos"}}) {
        nodes {
          ...DefaultImage
        }
      }
    }
  `)
  const tarjetaRef = useRef(null)
  useEffect(() => {
    const container = tarjetaRef.current
    const middle = container.children[Math.floor((container.children.length - 1) / 2)];
    container.scrollLeft = middle.offsetLeft +
      middle.offsetWidth / 2 - container.offsetWidth / 2;
  }, [])

  const image = getImage(query.allDataJson.nodes[0].images[0])

  return (
    <Styled>
      <div className="top">
        <img src={Logo} alt=""
          width="278"
          height="100"
        />
        <h3>
          Proyecto, desarrollo,
          dirección y construcción
          de obras
        </h3>

      </div>
      <div className="bottom">
        <div className="background">
          <GatsbyImage image={image} alt="Como lo hacemos" />
          <h3>
            ¿Cómo lo <span>hacemos</span>?
          </h3>
        </div>
        <div className="container">
          <div className="cards" ref={tarjetaRef}>
            <div className="card">
              <img src={Llave} alt="Llave en mano"
                width="90"
                height="90"
              />
              <p>Llave en Mano</p>
            </div>
            <div className="card">
              <img src={Trabajador} alt="Construcción Tradicional"
                width="90"
                height="90"
              />
              <p>Construcción Tradicional</p>
            </div>
            <div className="card">
              <img src={Casa} alt="Steel Frame"
                width="90"
                height="90"
              />
              <p>Steel Frame</p>
            </div>
          </div>
          <p>
            El trabajo en equipo facilita el cumplimiento de objetivos, incrementa la motivación y la creatividad, y favorece las habilidades sociales de cada uno.
          </p>
        </div>
      </div>
    </Styled>
  )
}

export default ComoLoHacemos