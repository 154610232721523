import React from 'react'
import { Container } from "./styled"
import TopBottom from "../TopBottom"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const BottomBG = ({ image }) => {
  const imgData = getImage(image)
  return (
    <Container>
      <GatsbyImage
        image={imgData}
        alt="Imagen de fondo"
      />
      <TopBottom />
    </Container>
  )
}

export default BottomBG