import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Tarjetas from "@/components/Tarjetas"
import { Card } from "./styled"

const GaleriaMobile = ({ data, setIsOpen, setSelectedItems }) => {
  const dataHalf = Math.floor(data.length / 2)
  const dataFirstHalf = data.slice(0, dataHalf)
  const dataSecondHalf = data.slice(dataHalf)

  return (
    <div>
      <Tarjetas>
        {dataFirstHalf.map((value, key) => {
          const cards = value.images
          const image = getImage(cards[0])
          return (
            <Card
              key={key}
              onClick={() => {
                setSelectedItems(cards)
                setIsOpen(true)
              }} >

              <GatsbyImage
                image={image}
                alt=" "
              />
              <div>
                <h3>{value.text}</h3>
              </div>
            </Card>
          )
        })
        }
      </Tarjetas>
      <Tarjetas>
        {
          dataSecondHalf.map((value, key) => {
            
            const cards = value.images
            const image = getImage(cards[0])
            return (
              <Card
                key={key}
                onClick={() => {
                  setSelectedItems(cards)
                  setIsOpen(true)
                }
                } >
                <GatsbyImage
                  image={image}
                  alt=" "
                />
                <div>
                  <h3>{value.text}</h3>
                </div>
              </Card>
            )
          })
        }
      </Tarjetas>
    </div>
  )
}

export default GaleriaMobile