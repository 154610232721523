import React, { useEffect, useRef } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Galeria as Styled, Card } from "./styled"

const GaleriaDesktop = ({data, setSelectedItems, setIsOpen}) => {
  const cardRef = useRef(null)
  useEffect(() => {
    const container = cardRef.current
    const middle = container.children[Math.floor((container.children.length - 1) / 2)];
    container.scrollLeft = middle.offsetLeft +
      middle.offsetWidth / 2 - container.offsetWidth / 2;
  }, [])


  return (
    <Styled ref={cardRef}>
      {data.map((value, key) => {
        const cards = value.images
        const image = getImage(cards[0])
        return (
          <Card
            key={key}
            onClick={() => {
              setSelectedItems(cards)
              setIsOpen(true)
            }} >

            <GatsbyImage
              image={image}
              alt=""
            />
            <div>
              <h3>{value.text}</h3>
            </div>
          </Card>
        )
      })
      }
    </Styled>
  )
}

export default GaleriaDesktop