import React from 'react'
import { graphql } from 'gatsby'

import Layout from '@/components/layout'
import Seo from '@/components/seo'

import Carrousel from "@/components/carrousel"
import Galeria from "../templates/proyectos/Galeria"
import ComoLoHacemos from "../templates/proyectos/ComoLoHacemos"
import BottomBG from "@/components/BottomBG"


const ProyectosBody = ({ data }) => {
  const [carrousel, bottom] = data.allDataJson.nodes
  return (
    <Layout>
      <Carrousel gallery={carrousel.images} text="“Creamos experiencias para las familias”" />
      <Galeria />
      <ComoLoHacemos />
      <BottomBG image={bottom.images[0]} />
    </Layout>
  )
}
export const Head = () => <Seo title="Proyectos" />

export const query = graphql`
  query {
    allDataJson(
      filter: {page: {eq: "proyectos"}, section: { in: ["carrousel", "bottom"] }}
      sort: {fields: section, order: DESC}
      ) {
      nodes {
        ...DefaultImage
      }
    }
  }
`


export default ProyectosBody