import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 150vh;
  background: linear-gradient(180deg, #FFFFFF 4%, rgba(243, 243, 243, 0.7) 19.39%, rgba(196, 196, 196, 0) 90%);;
  
  margin-top: 4rem;

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  @media(min-width: 768px) {
    height: 250vh;

  }
`