import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Wrapper } from "./styled"
import GaleriaDesktop from "./GaleriaDesktop"
import GaleriaMobile from "./GaleriaMobile"

import Separator from "@/components/styled/Separator"
import Modal from "@/components/Modal"


const Galeria = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [selectedItems, setSelectedItems] = useState(null);

  const query = useStaticQuery(graphql`
    query {
      allDataJson(filter: {page: {eq: "proyectos"}, section: {eq: "galeria"}}) {
        nodes {
          ...DefaultImage
        }
      }
    }
  `)

  const galeria = query.allDataJson.nodes


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);

    }
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }

    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  return (
    <Wrapper>
      <Separator
        width="min(85px, 6vw)"
        height="248px"
        x="8rem"
        directionX="right"
        y="-120px"
        opacity="0.7"
        // centered
        mobile={{
          directionX: "right",

          minWidth: "1024px",
          x: "1rem",
          y: "-80px",
          height: "160px",
          width: "63px"
        }}
      />
      {
        isMobile
          ? <GaleriaMobile
            data={galeria}
            setIsOpen={setIsOpen}
            setSelectedItems={setSelectedItems}
          />
          : <GaleriaDesktop
            setIsOpen={setIsOpen}
            setSelectedItems={setSelectedItems}
            data={galeria}
          />
      }
      <Modal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false) && setSelectedItems(null)}
        selectedItems={selectedItems}
      />
    </Wrapper>
  )
}

export default Galeria